import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"20\" cy=\"20\" r=\"20\" fill=\"white\" fill-opacity=\"0.12\"></circle><circle cx=\"20\" cy=\"20\" r=\"19.5\" stroke=\"white\" stroke-opacity=\"0.3\"></circle><g clip-path=\"url(#__clip0_15392_3127)\"><path d=\"M24.125 15.875L15.8754 24.1246\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M15.875 15.875L24.1246 24.1246\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><defs><clipPath id=\"__clip0_15392_3127\"><rect width=\"20\" height=\"20\" fill=\"white\" transform=\"translate(10 10)\"></rect></clipPath></defs>", 4)
  ])))
}
export default { render: render }